module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Hilbert (Yuang) Chen","short_name":"Hilbert (Yuang) Chen","start_url":"/","background_color":"#304CFD","theme_color":"#304CFD","display":"standalone","icon":"./static/favicon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"1ecdc26b614e6b3e803e075743318c6d"},
    },{
      plugin: require('../node_modules/gatsby-plugin-mdx/gatsby-browser.js'),
      options: {"plugins":[],"extensions":[".mdx",".md"],"remarkPlugins":[null],"rehypePlugins":[null],"gatsbyRemarkPlugins":[{"resolve":"gatsby-remark-copy-linked-files","options":{"destinationDir":"files","ignoreFileExtensions":["md"]}},{"resolve":"gatsby-remark-images","options":{"maxWidth":1000,"quality":80,"showCaptions":true,"linkImagesToOriginal":true}},{"resolve":"gatsby-remark-external-links","options":{"target":"_blank","rel":"nofollow"}},"gatsby-remark-autolink-headers","gatsby-remark-highlight.js"],"defaultLayouts":{},"lessBabel":false,"mediaTypes":["text/markdown","text/x-markdown"],"root":"/home/ubuntu/Projects/website"},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-XXXXXXXXX-X","head":false,"anonymize":false,"respectDNT":false,"exclude":[],"pageTransitionDelay":0,"enableWebVitalsTracking":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-nprogress/gatsby-browser.js'),
      options: {"plugins":[],"color":"black","showSpinner":true},
    },{
      plugin: require('../node_modules/gatsby-plugin-lunr/gatsby-browser.js'),
      options: {"plugins":[],"languages":[{"name":"en","plugins":[null]}],"fields":[{"name":"title","store":true,"attributes":{"boost":20}},{"name":"abstract","store":true},{"name":"content","store":true},{"name":"url","store":true}],"resolvers":{"Mdx":{}},"filename":"search_index.json","fetchOptions":{"credentials":"same-origin"}},
    },{
      plugin: require('../node_modules/gatsby-theme-academic/gatsby-browser.js'),
      options: {"plugins":[],"contentPath":"content","googleAnalyticTrackingId":"UA-XXXXXXXXX-X","defaultLanguage":"en","pages":{"home":"/","contact":"contact","resume":"hilbert_resume_public.pdf","research":"research","tags":"tags","posts":"posts"},"tagColors":["magenta","red","volcano","orange","gold","lime","green","cyan","blue","geekblue","purple"],"author":"Hilbert (Yuang) Chen","icon":"./static/favicon.png"},
    }]
